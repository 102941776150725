import React, { useState, useCallback } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const LIGHT_PURPLE = '#B975FF';
const LIGHT_PURPLE_HOVER = 'rgba(185, 117, 255, 0.2)';

const Features = () => {
    const [hoveredFeature, setHoveredFeature] = useState(null);

    const handleMouseEnter = useCallback((index) => setHoveredFeature(index), []);
    const handleMouseLeave = useCallback(() => setHoveredFeature(null), []);

    return (
        <Box sx={{ py: { xs: 8, sm: 12 }, position: 'relative', px: { xs: 2, sm: 0 } }}>
            <Typography 
                variant="h3" 
                align="center" 
                gutterBottom
                sx={{
                    mb: { xs: 4, sm: 6 },
                    fontWeight: 'bold',
                    fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                    background: 'linear-gradient(45deg, #B975FF, #8A2BE2)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                }}
            >
                Возможности бота
            </Typography>
            <Grid
                container
                spacing={{ xs: 2, sm: 4 }}
                justifyContent="center"
                sx={{
                    maxWidth: 'none',
                    width: '100%',
                    mx: 0,
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                {[
                    {
                        name: "Озвучка сообщений",
                        description: "Озвучка сообщений в чате, или же через аграду выделиь сообщение," +
                            " огромный выбор голосов",
                        docs_link: "https://docs.jeetbot.cc/category/text-to-speech",
                    },
                    {
                        name: "Punto Switcher",
                        description: "Pf,skb cvtybnm hfcrkflre/ Ntgthm 'nj cjdctv yt ghj,ktvf!",
                        hoverText: "Забыли сменить раскладку? Теперь это совсем не проблема!",
                        docs_link: "https://docs.jeetbot.cc/",
                    },
                    {
                        name: "Конвертер валют",
                        description: "Бот может автоматически понимать, что в сообщении есть валюта, " +
                            "отличная от вашей валюты указанной в настройках канала, и переводить ее в вашу валюту. ",
                        docs_link: "https://docs.jeetbot.cc/currency-converter/automatic-conversion",
                    },
                    {
                        name: "Shazam",
                        description: "Устали от постоянных вопросов, что за трек играет сейчас? Бот " +
                            "может самостоятельно определять трек и исполнителя, и отвечать зрителям.",
                        docs_link: "https://docs.jeetbot.cc/",
                    },
                    {
                        name: "Переводчик",
                        description: "Бот может автоматически переводить сообщения в вашем чате",
                        docs_link: "https://docs.jeetbot.cc/",
                    },
                    {
                        name: "И многое другое",
                        description: "Еще больше подробностей о возможностях бота можно найти в нашей документации",
                        docs_link: "https://docs.jeetbot.cc/",
                    },
                ].map((feature, index) => (
                    <Grid item xs={12} sm={6} key={feature.name} sx={{ mb: { xs: 1, sm: 2 } }}>
                        <Card
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                bgcolor: 'rgba(31, 32, 37, 0.8)',
                                backdropFilter: 'blur(10px)',
                                border: '1px solid rgba(185, 117, 255, 0.1)',
                                '&:hover': {
                                    transform: { xs: 'none', sm: 'translateY(-5px)' },
                                    boxShadow: `0 10px 20px rgba(185, 117, 255, 0.2)`,
                                    borderColor: 'rgba(185, 117, 255, 0.3)',
                                },
                                '&:active': {
                                    transform: { xs: 'translateY(-2px)', sm: 'translateY(-5px)' },
                                    boxShadow: `0 5px 10px rgba(185, 117, 255, 0.2)`,
                                },
                                transition: 'all 0.3s ease-in-out',
                                borderRadius: '16px',
                            }}
                            onTouchStart={() => handleMouseEnter(index)}
                            onTouchEnd={() => handleMouseLeave()}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <CardHeader
                                title={feature.name}
                                titleTypographyProps={{
                                    variant: 'h6',
                                    sx: {
                                        fontSize: { xs: '1.1rem', sm: '1.25rem' },
                                    }
                                }}
                                sx={{
                                    textAlign: 'left', 
                                    pb: 0,
                                    px: { xs: 2, sm: 3 }
                                }}
                            />
                            <CardContent sx={{
                                flexGrow: 1,
                                pt: 1,
                                pb: '16px !important',
                                px: { xs: 2, sm: 3 },
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                                minHeight: { xs: '150px', sm: '180px' },
                            }}>
                                <Box sx={{
                                    position: 'relative', 
                                    flexGrow: 1,
                                    mb: { xs: 3, sm: 4 }
                                }}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            transition: 'opacity 0.3s ease-in-out',
                                            opacity: hoveredFeature === index && feature.hoverText ? 0 : 1,
                                            zIndex: 1,
                                            fontSize: { xs: '0.9rem', sm: '1rem' },
                                            lineHeight: { xs: 1.4, sm: 1.6 },
                                        }}
                                    >
                                        {feature.description}
                                    </Typography>
                                    {feature.hoverText && (
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                transition: 'opacity 0.3s ease-in-out',
                                                opacity: hoveredFeature === index ? 1 : 0,
                                                zIndex: 2,
                                                fontSize: { xs: '0.9rem', sm: '1rem' },
                                                lineHeight: { xs: 1.4, sm: 1.6 },
                                            }}
                                        >
                                            {feature.hoverText}
                                        </Typography>
                                    )}
                                </Box>
                                <Link 
                                    to={feature.docs_link}
                                    style={{
                                        textDecoration: 'none',
                                        width: '100%',
                                        marginTop: 'auto'
                                    }}
                                >
                                    <Button
                                        fullWidth
                                        sx={{
                                            color: LIGHT_PURPLE,
                                            '&:hover': {
                                                backgroundColor: LIGHT_PURPLE_HOVER,
                                                transform: { xs: 'none', sm: 'translateY(-2px)' },
                                                boxShadow: `0 5px 15px ${LIGHT_PURPLE_HOVER}`,
                                            },
                                            '&:active': {
                                                transform: { xs: 'translateY(-1px)', sm: 'translateY(-2px)' },
                                            },
                                            transition: 'all 0.3s ease',
                                            textTransform: 'none',
                                            fontSize: { xs: '0.9rem', sm: '1rem' },
                                            py: { xs: 1, sm: 1.5 },
                                            px: { xs: 1, sm: 2 },
                                            ml: { xs: -1, sm: -2 },
                                        }}
                                    >
                                        Подробнее
                                    </Button>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Features; 