import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import theme from "../theme";
import MyCopyright from "../modules/common/copyright";
import { ym_hit } from "../utils/yandex";
import { Snackbar } from "@mui/material";
import TelegramButton from '../components/TelegramButton';
import { fetchSubscriptionPlans, defaultSubscriptionPlans } from "../data/subscriptionPlans";
import ScrollToTop from '../components/ScrollToTop';
import { useNotification } from '../context/NotificationContext';
import { TWITCH_REDIRECT_URI } from "../constants";
import Requester from "../utils/requester";

// Import new components
import Hero from '../components/home/Hero';
import Features from '../components/home/Features';
import ExampleVoices from '../components/home/ExampleVoices';
import FAQ from '../components/home/FAQ';
import { ScrollLink, ScrollToTopLink, MobileNavMenu } from '../components/home/ScrollLinks';
import { LIGHT_PURPLE } from '../components/home/constants';
import SubscriptionPlans from '../components/home/SubscriptionPlans';

export default function HomePage() {
    ym_hit('/');
    const { showNotification } = useNotification();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [cookieConsent, setCookieConsent] = React.useState(() => {
        return localStorage.getItem("cookieConsent") === "accepted";
    });

    const handleAcceptCookies = () => {
        localStorage.setItem("cookieConsent", "accepted");
        setCookieConsent(true);
    };

    // Check authentication status without redirect
    React.useEffect(() => {
        Requester.validateToken().then(result => {
            setIsAuthenticated(result);
        }).catch(error => {
            console.log(error);
            setIsAuthenticated(false);
        });
    }, []);

    const handleTwitchAuth = async () => {
        // If already authenticated, just redirect to dashboard
        if (isAuthenticated) {
            window.location.href = '/dashboard';
            return;
        }

        try {
            const response = await Requester.post('twitch/get_twitch_auth_url/', {
                redirect_url: TWITCH_REDIRECT_URI,
            });

            if (response.status !== 200) {
                showNotification('Ошибка при авторизации через Twitch', {
                    type: 'error',
                    autoHideDuration: 3000
                });
                return;
            }

            const data = await response.json();
            window.location.href = data.url;
        } catch (error) {
            console.log(error);
            showNotification('Ошибка при авторизации через Twitch', {
                type: 'error',
                autoHideDuration: 3000
            });
        }
    };

    // Update auth button text based on authentication status
    const authButton = (
        <Button
            variant="contained"
            onClick={handleTwitchAuth}
            startIcon={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: { xs: 20, sm: 24 },
                        height: { xs: 20, sm: 24 },
                        '& img': {
                            width: { xs: '16px', sm: '20px' },
                            height: { xs: '16px', sm: '20px' },
                        }
                    }}
                >
                    <img 
                        src="/twitch_icon.png" 
                        alt="Twitch"
                    />
                </Box>
            }
            sx={{
                backgroundColor: LIGHT_PURPLE,
                color: 'white',
                px: { xs: 1.5, sm: 3 },
                py: { xs: 0.75, sm: 1 },
                minWidth: { xs: 'auto', sm: '140px' },
                borderRadius: '12px',
                textTransform: 'none',
                fontSize: { xs: '0.85rem', sm: '0.95rem' },
                fontWeight: 500,
                '&:hover': {
                    backgroundColor: '#9B5FE3',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(185, 117, 255, 0.3)',
                },
                transition: 'all 0.3s ease',
            }}
        >
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {isAuthenticated ? 'Перейти в панель' : 'Войти через Twitch'}
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                {isAuthenticated ? 'Панель' : 'Войти'}
            </Box>
        </Button>
    );

    return (
        <ThemeProvider theme={theme}>
            <ScrollToTop />
            <CssBaseline/>
            <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <AppBar 
                    position="fixed"
                    elevation={0}
                    sx={{
                        background: 'rgba(31, 32, 37, 0.8)',
                        backdropFilter: 'blur(10px)',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    }}
                >
                    <Container maxWidth="lg">
                        <Toolbar
                            sx={{
                                py: { xs: 0.5, sm: 1 },
                                gap: { xs: 1, sm: 2 },
                                justifyContent: 'space-between',
                                minHeight: { xs: '56px', sm: '64px' },
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: { xs: 1, sm: 2 },
                                    flex: 1,
                                }}
                            >
                                <ScrollToTopLink
                                    to="/"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: { xs: 1, sm: 2 },
                                        textDecoration: 'none',
                                        color: 'inherit',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            '& img': {
                                                transform: 'scale(1.05)',
                                            },
                                            '& .logo-text': {
                                                color: LIGHT_PURPLE,
                                            },
                                        },
                                    }}
                                >
                                    <img
                                        src="/logo_nobg.png"
                                        alt="logo"
                                        style={{
                                            width: '32px',
                                            height: '32px',
                                            transition: 'transform 0.3s ease',
                                        }}
                                    />
                                    <Typography
                                        className="logo-text"
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'bold',
                                            transition: 'color 0.3s ease',
                                            display: { xs: 'none', sm: 'block' },
                                            fontSize: { sm: '1.15rem', md: '1.25rem' },
                                        }}
                                    >
                                        JeetBot
                                    </Typography>
                                </ScrollToTopLink>

                                <Box
                                    sx={{
                                        display: { xs: 'none', md: 'flex' },
                                        gap: 3,
                                        ml: 4,
                                    }}
                                >
                                    {[
                                        { label: 'Возможности', href: '#features' },
                                        { label: 'Голоса', href: '#voices' },
                                        { label: 'Тарифы', href: '#pricing' },
                                        { label: 'FAQ', href: '#faq' },
                                    ].map((item) => (
                                        <ScrollLink
                                            key={item.label}
                                            to={item.href}
                                            style={{ 
                                                textDecoration: 'none',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: 'text.secondary',
                                                    fontSize: '0.95rem',
                                                    fontWeight: 500,
                                                    '&:hover': {
                                                        color: LIGHT_PURPLE,
                                                    },
                                                    transition: 'color 0.2s ease',
                                                }}
                                            >
                                                {item.label}
                                            </Typography>
                                        </ScrollLink>
                                    ))}
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: { xs: 1, sm: 2 },
                                }}
                            >
                                <Box sx={{ display: 'flex', gap: { xs: 1, sm: 2 } }}>
                                    <TelegramButton />
                                    {authButton}
                                </Box>
                                <MobileNavMenu
                                    items={[
                                        { label: 'Возможности', href: '#features' },
                                        { label: 'Голоса', href: '#voices' },
                                        { label: 'Тарифы', href: '#pricing' },
                                        { label: 'FAQ', href: '#faq' },
                                    ]}
                                />
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Toolbar /> {/* Spacer for fixed AppBar */}
                <main style={{flex: 1}}>
                    <Hero onTwitchAuth={handleTwitchAuth}/>
                    <Container maxWidth="lg">
                        <Box id="features" sx={{ scrollMarginTop: '80px' }}><Features/></Box>
                        <Box id="voices" sx={{ scrollMarginTop: '80px' }}><ExampleVoices/></Box>
                        <Box id="pricing" sx={{ scrollMarginTop: '80px' }}>
                            <SubscriptionPlans onTwitchAuth={handleTwitchAuth}/>
                        </Box>
                        <Box id="faq" sx={{ scrollMarginTop: '80px' }}><FAQ/></Box>
                    </Container>
                </main>
                <Box sx={{bgcolor: 'background.paper', p: 6}} component="footer">
                    <MyCopyright/>
                </Box>
                <Snackbar
                    open={!cookieConsent}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    sx={{
                        '& .MuiPaper-root': {
                            maxWidth: '600px',
                            backgroundColor: 'rgba(255, 255, 255, 0.95)',
                            backdropFilter: 'blur(10px)',
                            borderRadius: '12px',
                            p: 2.5,
                            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
                            color: '#1F2025',
                        }
                    }}
                    message={
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Typography sx={{ fontSize: '0.95rem' }}>
                                Мы используем файлы cookie для улучшения рабты сайта. Продолжая использовать наш сайт, 
                                вы соглашаетесь с нашей {' '}
                                <Link 
                                    to="/terms" 
                                    style={{
                                        color: LIGHT_PURPLE,
                                        textDecoration: 'underline'
                                    }}
                                >
                                    политикой использования файлов cookie
                                </Link>
                            </Typography>
                        </Box>
                    }
                    action={
                        <Button 
                            onClick={handleAcceptCookies}
                            variant="contained"
                            sx={{
                                backgroundColor: LIGHT_PURPLE,
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#9B5FE3',
                                },
                                ml: 2,
                                px: 3,
                                py: 1,
                                borderRadius: '8px',
                                textTransform: 'none',
                                fontSize: '0.95rem'
                            }}
                        >
                            Принять
                        </Button>
                    }
                />
            </Box>
            <Container maxWidth="lg">
                <Box>
                    <Typography
                        variant="body2"
                        align="center"
                        sx={{
                            marginTop: '-40px',
                            fontSize: '11px',
                            opacity: 0.2
                        }}
                    >
                        ФИО: Попов Александр Алексеевич
                        ИНН: 742300618999
                        Email: admin@alexue4.dev
                    </Typography>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
