import React from 'react';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const LIGHT_PURPLE = '#B975FF';
const LIGHT_PURPLE_HOVER = 'rgba(185, 117, 255, 0.2)';

const Hero = ({ onTwitchAuth }) => (
    <Box
        sx={{
            bgcolor: 'background.paper',
            pt: { xs: 8, sm: 12 },
            pb: { xs: 6, sm: 8 },
            textAlign: 'center',
            position: 'relative',
            overflow: 'hidden',
        }}
    >
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'radial-gradient(circle at 50% 50%, rgba(185, 117, 255, 0.15), rgba(185, 117, 255, 0) 70%)',
                animation: 'pulse 8s ease-in-out infinite',
                '@keyframes pulse': {
                    '0%': { opacity: 0.5 },
                    '50%': { opacity: 0.8 },
                    '100%': { opacity: 0.5 },
                },
            }}
        />
        <Container maxWidth="lg">
            <Box sx={{ position: 'relative' }}>
                <img
                    src="/logo_nobg.png"
                    alt="TwitchBot Logo"
                    style={{
                        width: '200px',
                        height: '200px',
                        marginBottom: '1.5rem',
                        animation: 'float 6s ease-in-out infinite',
                        '@media (min-width: 600px)': {
                            width: '250px',
                            height: '250px',
                            marginBottom: '2rem',
                        },
                    }}
                />
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                    sx={{
                        fontWeight: 'bold',
                        mb: 3,
                        fontSize: { xs: '2.5rem', md: '3.5rem' },
                        background: 'linear-gradient(45deg, #B975FF, #8A2BE2)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                    }}
                >
                    Сделайте стримы интерактивнее
                </Typography>
                <Typography
                    variant="h5"
                    align="center"
                    color="text.secondary"
                    paragraph
                    sx={{
                        maxWidth: '800px',
                        mx: 'auto',
                        mb: 5,
                        fontSize: { xs: '1.1rem', md: '1.3rem' },
                        lineHeight: 1.6,
                    }}
                >
                    Мощный бот для Twitch с функциями озвучки сообщений, автоматического перевода и множеством других возможностей для улучшения взаимодействия со зрителями
                </Typography>
                <Box sx={{ 
                    display: 'flex', 
                    gap: { xs: 2, sm: 3 }, 
                    justifyContent: 'center', 
                    flexWrap: 'wrap',
                    px: { xs: 2, sm: 0 }
                }}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={onTwitchAuth}
                        className="landingButton"
                        sx={{
                            backgroundColor: LIGHT_PURPLE,
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#9B5FE3',
                                transform: 'translateY(-2px)',
                                boxShadow: `0 5px 15px ${LIGHT_PURPLE_HOVER}`,
                            },
                            transition: 'all 0.3s ease',
                            fontSize: { xs: '1rem', sm: '1.2rem' },
                            py: { xs: 1.2, sm: 1.5 },
                            px: { xs: 3, sm: 4 },
                            borderRadius: '30px',
                            width: { xs: '100%', sm: 'auto' },
                            maxWidth: { xs: '300px', sm: 'none' },
                        }}
                    >
                        Начать использовать бесплатно
                    </Button>
                    <Button
                        variant="outlined"
                        size="large"
                        component={Link}
                        to="https://docs.jeetbot.cc/"
                        sx={{
                            borderColor: LIGHT_PURPLE,
                            color: LIGHT_PURPLE,
                            '&:hover': {
                                borderColor: LIGHT_PURPLE,
                                backgroundColor: LIGHT_PURPLE_HOVER,
                                transform: 'translateY(-2px)',
                            },
                            transition: 'all 0.3s ease',
                            fontSize: { xs: '1rem', sm: '1.2rem' },
                            py: { xs: 1.2, sm: 1.5 },
                            px: { xs: 3, sm: 4 },
                            borderRadius: '30px',
                            width: { xs: '100%', sm: 'auto' },
                            maxWidth: { xs: '300px', sm: 'none' },
                        }}
                    >
                        Узнать больше
                    </Button>
                </Box>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    gap: { xs: 4, sm: 6, md: 8 }, 
                    mt: { xs: 6, sm: 8 },
                    flexWrap: 'wrap',
                    px: { xs: 2, sm: 0 }
                }}>
                    {[
                        { number: '1000+', label: 'Активных стримеров' },
                        { number: '50K+', label: 'Озвученных сообщений' },
                        { number: '10млн+', label: 'Обработанных сообщений' },
                    ].map((stat) => (
                        <Box key={stat.label} sx={{ 
                            textAlign: 'center',
                            minWidth: { xs: '140px', sm: 'auto' }
                        }}>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontWeight: 'bold',
                                    color: LIGHT_PURPLE,
                                    mb: 1,
                                    fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }
                                }}
                            >
                                {stat.number}
                            </Typography>
                            <Typography 
                                variant="subtitle1" 
                                color="text.secondary"
                                sx={{
                                    fontSize: { xs: '0.9rem', sm: '1rem' },
                                    maxWidth: { xs: '120px', sm: 'none' },
                                    mx: 'auto'
                                }}
                            >
                                {stat.label}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Container>
    </Box>
);

export default Hero; 