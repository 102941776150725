import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {Container, Typography, Box, CssBaseline, AppBar, Toolbar, Button} from '@mui/material';
import {Link} from 'react-router-dom';
import theme from '../theme';
import MyCopyright from '../modules/common/copyright';
import {ym_hit} from '../utils/yandex';
import TelegramButton from '../components/TelegramButton';
import {useEffect} from 'react';

const LIGHT_PURPLE = '#B975FF';

export default function Terms() {
    ym_hit('/terms');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <AppBar 
                    position="fixed"
                    elevation={0}
                    sx={{
                        background: 'rgba(31, 32, 37, 0.8)',
                        backdropFilter: 'blur(10px)',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    }}
                >
                    <Container maxWidth="lg">
                        <Toolbar
                            sx={{
                                py: { xs: 0.5, sm: 1 },
                                gap: { xs: 1, sm: 2 },
                                justifyContent: 'space-between',
                                minHeight: { xs: '56px', sm: '64px' },
                            }}
                        >
                            <Link to="/" style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: 'inherit',
                                cursor: 'pointer',
                            }}>
                                <img
                                    src="/logo_nobg.png"
                                    alt="logo"
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        transition: 'transform 0.3s ease',
                                    }}
                                />
                                <Typography 
                                    className="logo-text"
                                    variant="h6"
                                    sx={{
                                        ml: 2,
                                        fontWeight: 'bold',
                                        transition: 'color 0.3s ease',
                                        display: { xs: 'none', sm: 'block' },
                                        fontSize: { sm: '1.15rem', md: '1.25rem' },
                                        '&:hover': {
                                            color: LIGHT_PURPLE,
                                        },
                                    }}
                                >
                                    JeetBot
                                </Typography>
                            </Link>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: { xs: 1, sm: 2 },
                                }}
                            >
                                <TelegramButton/>
                                <Button
                                    variant="outlined"
                                    component={Link}
                                    to="/sign-in"
                                    className="landingButton"
                                    startIcon={<img src="/twitch_icon.png" alt="Twitch" width="24" height="24"/>}
                                    sx={{
                                        borderColor: LIGHT_PURPLE,
                                        color: LIGHT_PURPLE,
                                        '&:hover': {
                                            borderColor: LIGHT_PURPLE,
                                            backgroundColor: 'rgba(185, 117, 255, 0.1)',
                                            transform: 'translateY(-2px)',
                                            boxShadow: '0 5px 15px rgba(185, 117, 255, 0.2)',
                                        },
                                        transition: 'all 0.3s ease',
                                    }}
                                >
                                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        Войти через Twitch
                                    </Box>
                                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                        Войти
                                    </Box>
                                </Button>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Toolbar/> {/* Spacer for fixed AppBar */}

                <Container 
                    component="main" 
                    maxWidth="md" 
                    sx={{
                        mt: 4, 
                        mb: 4, 
                        flexGrow: 1,
                        px: { xs: 2, sm: 3 },
                    }}
                >
                    <Typography 
                        variant="h4" 
                        component="h1" 
                        gutterBottom 
                        align="center"
                        sx={{
                            fontWeight: 'bold',
                            mb: 3,
                        }}
                    >
                        Пользовательское соглашение Jeetbot
                    </Typography>

                    <Typography 
                        variant="subtitle1" 
                        gutterBottom 
                        align="center" 
                        color="text.secondary"
                        sx={{ mb: 4 }}
                    >
                        Российская Федерация, город Москва
                        <br/>
                        опубликовано в сети Интернет по адресу: https://jeetbot.cc/terms
                    </Typography>

                    <Box sx={{my: 2, textAlign: 'center', mb: 6}}>
                        <Typography variant="body2" color="text.secondary">
                            Дата публикации 25.11.2024
                            <br/>
                            Дата вступления в силу 25.11.2024
                        </Typography>
                    </Box>

                    <Box sx={{mb: 4}}>
                        <Typography variant="h6" gutterBottom sx={{mt: 4}}>
                            ОБЩАЯ ИНФОРМАЦИЯ ДЛЯ ПОЛЬЗОВАТЕЛЯ
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Настоящий документ – это предложение, адресованное неопределенному кругу физических лиц,
                            заключить соглашение с Jeetbot об условиях использования объектов Jeetbot и предоставления
                            услуг сервиса Jeetbot (как они определены далее).
                        </Typography>
                        <Typography variant="body1" paragraph>
                            В соответствии со ст. 428, 434 Гражданского кодекса Российской Федерации (далее – ГК РФ)
                            данное предложение является публичной офертой (далее – оферта) о заключении Соглашения в
                            виде договора присоединения и размещено по ссылке: https://jeetbot.cc/terms.
                        </Typography>
                    </Box>

                    <Box sx={{mb: 6}}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{mt: 4, fontWeight: 'bold'}}>
                            1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
                        </Typography>

                        <Typography variant="body1" paragraph>
                            В настоящей Оферте используются следующие термины и определения:
                        </Typography>

                        <Box sx={{pl: 3}}>
                            <Typography variant="body1" paragraph>
                                <strong>1.1. Jeetbot</strong> — физическое лицо, зарегистрированное в качестве
                                самозанятого Попов Александр Алексеевич (ИНН 742300618999), которому принадлежат
                                интеллектуальные права на объекты Jeetbot, а также на составляющие их результаты
                                интеллектуальной деятельности.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.2. Сервис</strong> — программное обеспечение Jeetbot в целом, включающее
                                веб-сайт, бота, API и иные компоненты, доступные по адресу jeetbot.cc.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.3. Бот</strong> — автоматизированная программа Jeetbot, функционирующая на
                                платформе Twitch.tv, способная взаимодействовать с чатом, выполнять команды и
                                предоставлять различные функции для управления трансляцией.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.4. Команда</strong> — текстовое сообщение специального формата, отправляемое в
                                чат Twitch для взаимодействия с Ботом.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.5. Пользователь</strong> — физическое или юридическое лицо, принявшее условия
                                настоящей Оферты и использующее Сервис.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.6. Стример</strong> — Пользователь, являющийся владельцем канала на платформе
                                Twitch.tv, использующий Сервис для управления своей трансляцией.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.7. Подписчик</strong> — Пользователь, оплативший доступ к расширенным функциям
                                Сервиса.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.8. Бесплатный пользователь</strong> — Пользователь, использующий базовые
                                функции Сервиса без оплаты.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.9. Личный кабинет</strong> — защищенная часть Сервиса, доступная после
                                авторизации, позволяющая управлять настройками Бота и доступными функциями.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.10. Канал</strong> — персональная страница Стримера на платформе Twitch.tv,
                                где осуществляется трансляция и происходит взаимодействие с Ботом.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.11. Функции</strong> — различные возможности и инструменты, предоставляемые
                                Сервисом, доступные в зависимости от типа подписки.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.12. Настройки</strong> — параметры работы Бота, которые может изменять
                                Пользователь через Личный кабинет.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.13. Контент</strong> — любые текстовые, графические, аудио и иные материалы,
                                создаваемые или обрабатываемые с помощью Сервиса.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.14. Модерация</strong> — процесс контроля и управления действиями Бота на
                                канале, включая настройку автоматических действий и фильтров.
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>1.15. Учетные данные</strong> — информация, необходимая для идентификации
                                Пользователя при использовании Сервиса (логин, пароль, токены доступа).
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{mb: 6}}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{mt: 4, fontWeight: 'bold'}}>
                            2. АКЦЕПТ ОФЕРТЫ И РЕГИСТРАЦИЯ
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            2.1. Акцепт оферты
                        </Typography>

                        <Typography variant="body1" paragraph>
                            2.1.1. Акцептом оферты и моментом заключения соглашения между Пользователем и Исполнителем
                            является:
                        </Typography>

                        <Typography variant="body1" component="div" sx={{pl: 3, mb: 2}}>
                            • момент создания аккаунта на сайте jeetbot.cc (регистрация);<br/>
                            • момент начала использования Сервиса без регистрации;<br/>
                            • для Подписчиков - момент оплаты подписки.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            2.1.2. Акцептуя оферту, Пользователь:
                        </Typography>

                        <Typography variant="body1" component="div" sx={{pl: 3, mb: 2}}>
                            • подтверждает, что в полном объеме ознакомился с условиями настоящей оферты, в полной мере
                            понял и согласен со всеми условиями;<br/>
                            • подтверждает, что является совершеннолетним дееспособным лицом, либо несовершеннолетним
                            лицом, объявленным по решению уполномоченного органа полностью дееспособным (эмансипация),
                            либо несовершеннолетним лицом, достигшим четырнадцати лет и получившим письменное разрешение
                            в требуемой законом форме от своих родителей или иных законных представителей;<br/>
                            • понимает и подтверждает, что с момента акцепта оферты он не вправе ссылаться на
                            неосведомленность и/или несогласие с какими-либо из условий оферты;<br/>
                            • подтверждает, что все его действия совершаются осознанно, без принуждения и соответствуют
                            его действительным намерениям.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            2.1.3. Исполнитель вправе в любое время в одностороннем порядке изменить условия Оферты или
                            отозвать её, уведомив Пользователя путём размещения соответствующего уведомления в Личном
                            кабинете и/или на сайте jeetbot.cc.

                            Новая редакция Оферты вступает в силу с момента её размещения на сайте jeetbot.cc/terms,
                            если иное прямо не предусмотрено в новой редакции.

                            Пользователь обязуется самостоятельно отслеживать изменения условий Оферты. В случае
                            несогласия с новой редакцией Пользователь вправе прекратить использование Сервиса и
                            расторгнуть Соглашение.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            2.1.4. Риски и последствия, связанные с не ознакомлением с размещенной на сайте информацией,
                            несет Пользователь. Исполнитель не несет ответственности за убытки Пользователя, возникшие в
                            связи с не ознакомлением с размещенной на сайте информацией.
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            2.2. Регистрация
                        </Typography>

                        <Typography variant="body1" paragraph>
                            2.2.1. Для создания аккаунта в Сервисе Пользователю необходимо пройти регистрацию на сайте
                            jeetbot.cc путем нажатия кнопки "Войти через Twitch" или аналогичной кнопки, предназначенной
                            для авторизации.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            2.2.2. Регистрация в Сервисе осуществляется через вход (авторизацию) в платформу Twitch.tv,
                            в соответствии с правилами и условиями данной платформы. Пользователь самостоятельно несет
                            ответственность за:<br/>
                            а) правильность и достоверность указываемых при входе данных;<br/>
                            б) соблюдение правил и условий платформы Twitch.tv;<br/>
                            в) актуальность предоставляемых данных.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            2.2.3. В случае если Пользователь не имеет аккаунта на платформе Twitch.tv, перед
                            регистрацией в Сервисе Пользователю необходимо:<br/>
                            а) зарегистрироваться на платформе Twitch.tv в соответствии с правилами и условиями данной
                            платформы;<br/>
                            б) после регистрации на Twitch.tv пройти регистрацию в Сервисе в соответствии с п. 2.2.2
                            настоящего соглашения.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            2.2.4. Пользователь не вправе осуществлять регистрацию в Сервисе:<br/>
                            а) от имени или вместо другого лица;<br/>
                            б) используя чужие данные авторизации;<br/>
                            в) используя временные или одноразовые email-адреса;<br/>
                            за исключением случаев, когда Пользователь обладает оформленными в установленном
                            законодательством РФ порядке необходимыми полномочиями.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            2.2.5. Исполнитель оставляет за собой право:<br/>
                            а) отказать в регистрации без объяснения причин;<br/>
                            б) заблокировать или удалить аккаунт Пользователя в случае нарушения условий настоящей
                            оферты;<br/>
                            в) потребовать дополнительную верификацию Пользователя в случае подозрительной активности.
                        </Typography>
                    </Box>
                    <Box sx={{mb: 6}}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{mt: 4, fontWeight: 'bold'}}>
                            3. УСЛОВИЯ И ПОРЯДОК ИСПОЛЬЗОВАНИЯ СЕРВИСА
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            3.1. Общие положения
                        </Typography>

                        <Typography variant="body1" paragraph>
                            3.1.1. Сайт jeetbot.cc является объектом интеллектуальной собственности Исполнителя и
                            включает в себя:<br/>
                            а) программы и программные коды;<br/>
                            б) графические и текстовые файлы;<br/>
                            в) системы администрирования данных;<br/>
                            г) дополнительные программные модули;<br/>
                            д) текстовые, визуальные и аудиовизуальные компоненты;<br/>
                            е) домен и иные объекты интеллектуальных прав.
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            3.2. Функциональность Сервиса
                        </Typography>

                        <Typography variant="body1" paragraph>
                            3.2.1. Пользователь вправе использовать Сервис только в рамках явно предоставленной
                            функциональности следующими способами:<br/>
                            а) создавать аккаунт в Сервисе;<br/>
                            б) входить в свой аккаунт неограниченное количество раз (за исключением случаев блокировки);<br/>
                            в) знакомиться с информацией, размещенной на сайте;<br/>
                            г) использовать функции бота в соответствии с выбранным планом подписки.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            3.2.2. Бот Jeetbot является интеллектуальной собственностью Исполнителя и состоит из:<br/>
                            а) программных компонентов;<br/>
                            б) систем обработки команд;<br/>
                            в) интерфейсов взаимодействия;<br/>
                            г) модулей интеграции с Twitch;<br/>
                            д) иных технических компонентов.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            3.2.3. Пользователь вправе использовать Бота только следующими способами:<br/>
                            а) настраивать функции Бота через Личный кабинет;<br/>
                            б) использовать команды Бота в чате Twitch;<br/>
                            в) осуществлять настройку доступных параметров, за исключением удаления или изменения
                            упоминаний и отметок Jeetbot;<br/>
                            г) получать и обрабатывать данные через API (при наличии такой функции в подписке).
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            3.3. Ограничения использования
                        </Typography>

                        <Typography variant="body1" paragraph>
                            3.3.1. Пользователь не вправе:<br/>
                            а) декомпилировать, вскрывать технологию, разбирать код Сервиса;<br/>
                            б) вносить изменения в работу Сервиса;<br/>
                            в) препятствовать нормальной работе Сервиса;<br/>
                            г) удалять или изменять товарные знаки, логотипы и иные идентификаторы Jeetbot;<br/>
                            д) передавать права на использование аккаунта третьим лицам;<br/>
                            е) использовать Сервис для нарушения правил платформы Twitch;<br/>
                            ж) использовать Сервис для распространения запрещенного контента.
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            3.4. Контент и модерация
                        </Typography>

                        <Typography variant="body1" paragraph>
                            3.4.1. Пользователь не вправе использовать Сервис для:<br/>
                            а) распространения контента, противоречащего законодательству РФ;<br/>
                            б) размещения оскорбительных материалов;<br/>
                            в) использования нецензурной лексики;<br/>
                            г) нарушения общественного порядка;<br/>
                            д) нарушения прав третьих лиц.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            3.4.2. Исполнитель имеет право (но не обязанность):<br/>
                            а) модерировать любой контент, создаваемый через Сервис;<br/>
                            б) отключать доступ к функциям, используемым с нарушением правил;<br/>
                            в) блокировать аккаунты нарушителей без предупреждения.
                        </Typography>
                    </Box>
                    <Box sx={{mb: 6}}>
                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            4.1. Общие условия
                        </Typography>

                        <Typography variant="body1" paragraph>
                            4.1.1. Сервис предоставляется на условиях "как есть". Исполнитель:<br/>
                            а) не гарантирует соответствие Сервиса требованиям и ожиданиям Пользователя;<br/>
                            б) не гарантирует бесперебойную и безошибочную работу Сервиса, за исключением случаев,
                            предусмотренных законом;<br/>
                            в) несет ответственность только за убытки, причиненные Пользователю в результате прямого
                            нарушения обязательств по настоящей Оферте, если они вызваны умышленными действиями или
                            грубой неосторожностью Исполнителя.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            4.1.2. При этом Исполнитель не несет ответственности за:<br/>
                            • действия третьих лиц (включая платформу Twitch.tv);<br/>
                            • сбои или ограничения, вызванные действиями самого Пользователя;<br/>
                            • непреодолимую силу (форс-мажор).
                        </Typography>
                    </Box>
                    <Box sx={{mb: 6}}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{mt: 4, fontWeight: 'bold'}}>
                            5. СТОИМОСТЬ УСЛУГ И ПОРЯДОК ОПЛАТЫ
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            5.1. Общие положения
                        </Typography>

                        <Typography variant="body1" paragraph>
                            5.1.1. Базовые функции Сервиса предоставляются бесплатно.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            5.1.2. Стоимость подписки на расширенные функции указана на сайте jeetbot.cc и может быть
                            изменена Исполнителем в любое время в одностороннем порядке.
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            5.2. Порядок оплаты
                        </Typography>

                        <Typography variant="body1" paragraph>
                            5.2.1. Оплата производится в российских рублях через доступные способы оплаты, указанные на
                            сайте.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            5.2.2. При оплате услуг Исполнитель направляет Пользователю чек в соответствии с
                            законодательством РФ о самозанятых.
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            5.3. Возврат денежных средств
                        </Typography>

                        <Typography variant="body1" paragraph>
                            5.3.1. Возврат денежных средств за оплаченную подписку осуществляется только за
                            неиспользованное время подписки при условии, что:<br/>
                            а) Пользователь заранее уведомил Исполнителя о намерении прекратить использование
                            Сервиса;<br/>
                            б) остаток срока подписки составляет не менее 10 календарных дней.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            5.3.2. Возврат денежных средств не производится в случаях:<br/>
                            а) полного использования подписки (период действия подписки завершён);<br/>
                            б) блокировки аккаунта Пользователя за нарушение условий настоящей Оферты;<br/>
                            в) отказа Пользователя от подписки по причинам, не связанным с работой Сервиса (например,
                            отсутствие времени или изменение личных предпочтений);<br/>
                            г) использования Сервиса для целей, нарушающих законодательство РФ или настоящую Оферту.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            5.3.3. Размер возврата рассчитывается пропорционально оставшемуся времени подписки с учётом
                            даты получения запроса Пользователя о прекращении подписки.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            5.3.4. Возврат осуществляется в течение 10 рабочих дней с момента получения запроса и
                            проверки отсутствия нарушений Пользователем условий настоящей Оферты.
                        </Typography>
                    </Box>
                    <Box sx={{mb: 6}}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{mt: 4, fontWeight: 'bold'}}>
                            6. ОСОБЫЕ УСЛОВИЯ ПОДПИСКИ
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            6.1. Продление подписки
                        </Typography>

                        <Typography variant="body1" paragraph>
                            6.1.1. В случае технических сбоев или недоступности Сервиса по вине Исполнителя, срок
                            подписки может быть продлен по усмотрению Исполнителя.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            6.1.2. Решение о продлении подписки и сроке продления принимается Исполнителем индивидуально
                            в каждом конкретном случае.
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            6.2. Ограничения
                        </Typography>

                        <Typography variant="body1" paragraph>
                            6.2.1. Исполнитель оставляет за собой право:<br/>
                            а) отказать в продлении подписки без объяснения причин;<br/>
                            б) изменять набор функций в рамках подписки;<br/>
                            в) вводить новые ограничения для подписок.
                        </Typography>
                    </Box>
                    <Box sx={{mb: 6}}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{mt: 4, fontWeight: 'bold'}}>
                            7. СРОК ДЕЙСТВИЯ И ИЗМЕНЕНИЕ ОФЕРТЫ
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            7.1. Срок действия
                        </Typography>

                        <Typography variant="body1" paragraph>
                            7.1.1. Оферта вступает в силу с момента размещения на сайте jeetbot.cc и действует до
                            момента ее отзыва Исполнителем.
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            7.2. Изменение условий
                        </Typography>

                        <Typography variant="body1" paragraph>
                            7.2.1. Исполнитель оставляет за собой право:<br/>
                            а) внести изменения в условия Оферты;<br/>
                            б) отозвать Оферту в любой момент по своему усмотрению.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            7.2.2. Пользователь обязуется самостоятельно следить за изменениями Оферты путем
                            периодического ознакомления с актуальной редакцией.
                        </Typography>
                    </Box>
                    <Box sx={{mb: 6}}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{mt: 4, fontWeight: 'bold'}}>
                            8. ИСПОЛЬЗОВАНИЕ COOKIE-ФАЙЛОВ
                        </Typography>

                        <Typography variant="body1" paragraph>
                            8.1. Для обеспечения корректной работы Сервиса, а также для улучшения качества
                            предоставляемых услуг, Исполнитель использует cookie-файлы. Cookie-файлы — это небольшие
                            текстовые файлы, которые автоматически сохраняются на устройстве Пользователя при посещении
                            сайта jeetbot.cc.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            8.2. Cookie-файлы используются для следующих целей:<br/>
                            • сохранение пользовательских предпочтений и настроек (например, авторизации);<br/>
                            • обеспечение безопасности и предотвращение мошенничества;<br/>
                            • сбор обезличенных статистических данных об использовании Сервиса;<br/>
                            • улучшение функциональности и интерфейса Сервиса.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            8.3. Пользователь вправе в любой момент отключить использование cookie-файлов в настройках
                            своего браузера. Однако это может привести к ограничению доступа к отдельным функциям
                            Сервиса.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            8.4. Cookie-файлы, используемые Сервисом, не содержат персональных данных, таких как ФИО,
                            контактная информация или финансовые данные.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            8.5. Продолжая использовать Сервис, Пользователь выражает согласие с использованием
                            cookie-файлов в соответствии с условиями настоящей Оферты.
                        </Typography>
                    </Box>
                    <Box sx={{mb: 6}}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{mt: 4, fontWeight: 'bold'}}>
                            9. ИНФОРМАЦИЯ О ДАННЫХ ПОЛЬЗОВАТЕЛЯ
                        </Typography>

                        <Typography variant="body1" paragraph>
                            9.1. В рамках использования Сервиса Исполнитель не обрабатывает персональные данные
                            Пользователей, так как информация, передаваемая от платформы Twitch.tv, ограничивается:<br/>
                            • уникальным идентификатором (ID) аккаунта Twitch;<br/>
                            • никнеймом Пользователя на платформе Twitch.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            9.2. Эти данные:<br/>
                            • не позволяют идентифицировать личность конкретного физического лица;<br/>
                            • используются исключительно для функционирования Сервиса (идентификации Пользователя в
                            системе, предоставления доступа к функциям Сервиса и управления настройками Бота).
                        </Typography>

                        <Typography variant="body1" paragraph>
                            9.3. Исполнитель не собирает, не хранит и не обрабатывает никакие другие данные
                            Пользователя, включая, но не ограничиваясь:<br/>
                            • электронными адресами;<br/>
                            • паролями;<br/>
                            • платежной информацией;<br/>
                            • данными профиля на платформе Twitch, не связанными с уникальным ID и никнеймом.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            9.4. Пользователь вправе запросить удаление своих данных (ID и никнейма) из системы,
                            направив запрос на электронный адрес Исполнителя. В этом случае данные удаляются в течение
                            30 календарных дней, после чего Пользователь теряет возможность использовать Сервис.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            9.5. Исполнитель гарантирует, что данные Пользователя:<br/>
                            • не передаются третьим лицам;<br/>
                            • не используются в коммерческих целях, кроме предоставления функциональности Сервиса.
                        </Typography>
                    </Box>
                    <Box sx={{mb: 6}}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{mt: 4, fontWeight: 'bold'}}>
                            10. ИСПОЛЬЗОВАНИЕ ФАЙЛОВ COOKIE
                        </Typography>

                        <Typography variant="body1" paragraph>
                            10.1. Наш сайт использует файлы cookie и схожие технологии, чтобы гарантировать максимальное
                            удобство пользователям.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            10.2. Файлы cookie используются для следующих целей:<br/>
                            • обеспечения работы основных функций сайта;<br/>
                            • анализа использования сайта для его улучшения;<br/>
                            • сохранения пользовательских настроек;<br/>
                            • обеспечения безопасности.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            10.3. Пользователь может отключить использование файлов cookie в настройках браузера, однако
                            это может привести к ограничению доступа к определенным функциям сайта.
                        </Typography>
                    </Box>
                    <Box sx={{mb: 6}}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{mt: 4, fontWeight: 'bold'}}>
                            11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            11.1. Применимое право
                        </Typography>

                        <Typography variant="body1" paragraph>
                            11.1.1. К настоящей Оферте и отношениям между Исполнителем и Пользователем применяется
                            законодательство Российской Федерации.
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{mt: 3}}>
                            11.2. Разрешение споров
                        </Typography>

                        <Typography variant="body1" paragraph>
                            11.2.1. Все споры и разногласия решаются путем переговоров. В случае невозможности
                            достижения
                            согласия, споры решаются в судебном порядке по месту нахождения Исполнителя.
                        </Typography>
                    </Box>

                    <Box sx={{mb: 6}}>
                        <Typography variant="h5" component="h2" gutterBottom sx={{mt: 4, fontWeight: 'bold'}}>
                            12. РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ
                        </Typography>

                        <Typography variant="body1">
                            ФИО: Попов Александр Алексеевич<br/>
                            ИНН: 742300618999<br/>
                            Email: admin@alexue4.dev
                        </Typography>
                    </Box>

                </Container>

                <Box 
                    component="footer" 
                    sx={{
                        bgcolor: 'background.paper', 
                        p: 6,
                        mt: 'auto',
                        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                    }}
                >
                    <MyCopyright/>
                </Box>
            </Box>
            <Container maxWidth="lg">
                <Box>
                    <Typography
                        variant="body2"
                        align="center"
                        sx={{
                            marginTop: '-40px',
                            fontSize: '11px',
                            opacity: 0.2
                        }}
                    >
                        ФИО: Попов Александр Алексеевич
                        ИНН: 742300618999
                        Email: admin@alexue4.dev
                    </Typography>
                </Box>
            </Container>
        </ThemeProvider>
    );
}