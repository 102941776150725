import React, { useState, useRef } from 'react';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import Slider from '@mui/material/Slider';
import SpeedIcon from '@mui/icons-material/Speed';
import Typography from '@mui/material/Typography';

const LIGHT_PURPLE = '#B975FF';

const CustomAudioPlayer = ({ audioSrc }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playbackRate, setPlaybackRate] = useState(1);
    const audioRef = useRef(null);

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
        setProgress(progress);
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
    };

    const handleEnded = () => {
        setIsPlaying(false);
        setProgress(0);
    };

    const handleProgressClick = (event) => {
        const bounds = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - bounds.left;
        const width = bounds.width;
        const percentage = (x / width) * 100;
        const time = (percentage / 100) * duration;
        audioRef.current.currentTime = time;
        setProgress(percentage);
    };

    const handlePlaybackRateChange = (event, newValue) => {
        setPlaybackRate(newValue);
        if (audioRef.current) {
            audioRef.current.playbackRate = newValue;
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <Box
            sx={{
                bgcolor: 'rgba(31, 32, 37, 0.8)',
                backdropFilter: 'blur(10px)',
                borderRadius: '12px',
                p: { xs: 1.5, sm: 2 },
                border: '1px solid rgba(185, 117, 255, 0.1)',
                '&:hover': {
                    borderColor: 'rgba(185, 117, 255, 0.3)',
                    boxShadow: '0 4px 12px rgba(185, 117, 255, 0.1)',
                },
                transition: 'all 0.3s ease',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2 } }}>
                    <IconButton
                        onClick={togglePlay}
                        sx={{
                            color: LIGHT_PURPLE,
                            p: { xs: 1, sm: 1.5 },
                            '&:hover': {
                                backgroundColor: 'rgba(185, 117, 255, 0.1)',
                            },
                        }}
                    >
                        {isPlaying ? (
                            <PauseRoundedIcon sx={{ fontSize: { xs: 24, sm: 28 } }} />
                        ) : (
                            <PlayArrowRoundedIcon sx={{ fontSize: { xs: 24, sm: 28 } }} />
                        )}
                    </IconButton>
                    
                    <Box sx={{ flexGrow: 1 }}>
                        <Box
                            onClick={handleProgressClick}
                            sx={{
                                height: { xs: 6, sm: 4 },
                                bgcolor: 'rgba(185, 117, 255, 0.1)',
                                borderRadius: { xs: 3, sm: 2 },
                                cursor: 'pointer',
                                position: 'relative',
                                '&:hover': {
                                    '& .progress-bar': {
                                        height: { xs: 8, sm: 6 },
                                        marginTop: '-1px',
                                    },
                                },
                            }}
                        >
                            <Box
                                className="progress-bar"
                                sx={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    height: { xs: 6, sm: 4 },
                                    width: `${progress}%`,
                                    bgcolor: LIGHT_PURPLE,
                                    borderRadius: { xs: 3, sm: 2 },
                                    transition: 'all 0.2s ease',
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: { xs: 0.5, sm: 1 },
                                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                                color: 'text.secondary',
                            }}
                        >
                            <span>{formatTime(audioRef.current?.currentTime || 0)}</span>
                            <span>{formatTime(duration)}</span>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <SpeedIcon sx={{ color: LIGHT_PURPLE, fontSize: 20 }} />
                    <Box sx={{ flexGrow: 1 }}>
                        <Slider
                            value={playbackRate}
                            onChange={handlePlaybackRateChange}
                            min={0.5}
                            max={2}
                            step={0.1}
                            sx={{
                                color: LIGHT_PURPLE,
                                '& .MuiSlider-thumb': {
                                    width: 16,
                                    height: 16,
                                },
                                '& .MuiSlider-rail': {
                                    opacity: 0.3,
                                },
                            }}
                        />
                    </Box>
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'text.secondary',
                            minWidth: 40,
                            textAlign: 'right',
                        }}
                    >
                        {playbackRate.toFixed(1)}x
                    </Typography>
                </Box>
            </Box>
            <audio
                ref={audioRef}
                src={audioSrc}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
                onEnded={handleEnded}
            />
        </Box>
    );
};

export default CustomAudioPlayer; 